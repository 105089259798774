export default {
    partnersTitle:        "Наші однодумці",
    reviewsTitle:         "Враження від платформи",
    specialistsTitle:     "Провідні спеціалісти",
    specialistsDescr:     "Ваша емоційна стабільність - наша головна мета. Тому в нашій команді працюють лише фахівці з сертифікацією та досвідом роботи. Ми гарантуємо професіоналізм психологів суворим відбором.",
    specialistsBtn:       "Усі психологи",
    resultTitle:          "Вашу заявку успішно зареєстровано на сайті \"Розкажи мені\".",
    resultDescript:       "Вже скоро з вами зв'яжеться наш спеціаліст. Очікуйте, і ми допоможемо вам досягнути психологічного комфорту.",
    getHelpTitle:         "Звернутися за підтримкою",
    getHelpLoad:          "Обробка даних...",
    getHelpNameLabel:     "Ваше ім'я",
    getHelpNamePholder:   "Введіть ім'я",
    getHelpSnameLabel:    "Ваше прізвище",
    getHelpSnamePholder:  "Введіть прізвище",
    getHelpRadioGender:   "Стать",
    getHelpAdult:         "Підтверджую що мені є 18",
    getHelpTel:           "Номер телефону",
    placeholderPhone:     "Код країни, номер телефону",
    getHelpLabel:         "Що вас турбує?",
    areaGetHelpLabel:     "Коротко опишіть ваш запит",
    areaGetHelpPholder:   "Почніть вводити",
    getHelpFeedBackLabel: "Вкажіть спосіб за яким з вами можна зв’язатись в месенджері",

    getHelpAgreeText:   "Я приймаю умови конфіденційності та обробки персональних даних.",
    getHelpAgreeLink:   "Детальніше",
    getHelpTelList:     "В мене немає месенджерів",
    getHelpMale:        "Чоловік",
    getHelpFemale:      "Жінка",
    contentLoading:     "Завантажуемось...",
    modalGetTitle:      "Допоможіть нам допомагати вам!",
    modalBefore:        "Наші фахівці працюють як волонтери-психологи, і ми дуже цінуємо їхній час. Тому закликаємо вас дотримуватися регламенту консультації:",
    modalAfter0:        "1). Наші спеціалісти надають ",
    modalAfter:         " консультацію тривалістю 30-60 хвилин (залежно від запиту на розсуд фахівця) .",
    modalAfterBr:       "Сподіваємося на ваше розуміння та толерантність.",
    modalAfterBr1:      "2). Психолог зв’яжеться з вами за допомогою смс, текстового повідомлення в месенджері або дзвінка для узгодження дати й часу консультації. Якщо ви не відповідаєте протягом 2 діб, звернення анулюється.",
    modalAfterBr2:      "3). Якщо ви запізнитеся на консультацію, її час скорочується на відповідну кількість хвилин.",
    modalAfterBr3:      "4). Психолог має право перервати консультацію в таких випадках: якщо пацієнт знаходиться під дією наркотичних речовин чи алкоголю, поводиться агресивно і ображає психолога, порушує порядок під час розмови.",
    modalAfterBr4:      "5). Запити від неповнолітніх будуть переадресовані на ресурси партнерів.",
    modalAfterBr5:      "6). Запити на консультацію приймаються лише від першої особи, тобто родичі чи знайомі не можуть звертатися із проханням допомогти іншій людині. Виняток — неповнолітні особи (якщо звернення від батьків).",
    modalButtonAgree:   "Погоджуюсь",
    modalButtonDecline: "Повернутися",
    resFotmOnErr:       "Сталася технічна помилка",
    resFotmOnErrDesc:   "Ми вже працюємо над її вирішенням. Спробуйте повторити ваші дії через 15 хвилин.",
    resFotmOnErrNote:   "Якщо результат буде таким же, то напишіть нам на пошту",
    giveHelpResTitle:   "Ваша форма успішно заповнена",
    giveHelpResDesc:    "Дякуємо вам за те, що долучилися до нашої ініціативи. З вами обов'язково зв’яжуться наші фахівці одразу після обробки анкети.",
    giveHelpTitle:      "Долучитися до ініціативи",
    giveHelpLoader:     "Обробка даних...",
    giveHelpEmail:      "Електронна пошта",
    giveHelpPassword:   "Повторіть пароль",
    giveHelpAgree:      "Детальніше",
    giveHelpAgreeText:  "Я приймаю умови конфіденційності та обробки персональних даних",
    giveHelpEducation:  "Ваша освіта",
    giveHelpEducPehold: "Введіть інформацію",
    giveHelpSpec:       "Які ваші сфери спеціалізації",
    giveHelpDownload:   "Оберіть файл",
    giveHelpDownEd:     "Додайте один або декілька документів, що підтверджує вашу кваліфікацію спеціаліста",
    giveHelpResume:     "Оберіть резюме",
    giveHelpDownResume: "Завантажте Ваше резюме в форматі .doc .docx .pdf",
    buttonSubmit:       "Відправити",
    buttonContinue:     "Далі",
    buttonMain:         "На головну",
    fieldSelect:        "Зробіть вибір",
    buttonMore:         "Читати більше",
    buttonLess:         "Згорнути",
    giveHelpSpecError:  "Виберіть сфери спеціалізації",
    forbidden:          "Доступ до сервісу заблоковано",
    "privacy":        "Конфідеційність",
    "specialists":    "Спеціалісти",
    "partners":       "Партнери",
    "about":          "Про нас",
    "howWeWork":      "Як ми працюємо",
    "faq":            "FAQ",
    "contacts":       "Контакти",
    "toAdmin":        "Увійти",
    "address":        "Адреса",
    "addressLink":    "Інститут глобальної трансформації (Institute for Global Transformation), вул. Предславинська, 40",
    "social":         "Соціальні мережі",
    "footerPrivacy1": "Політика конфіденційності та",
    "footerPrivacy2": "обробка персональних даних",
    "footerRules":    "Правила користування сайтом",
    "tel":            "Телефон технічної підтримки",

    "cookieText":   "Цей веб-сайт використовує файли cookie для підвищення зручності роботи з веб-сайтом",
    "cookieLink":   "Детальніше",
    "cookieButton": "Приймаю",

    "homePrivacyTitle": "Тільки між нами",
    "homePrivacyText":  "Ми розуміємо, як важко іноді буває відкритися сторонній людині, тому всі наші користувачі мають право не повідомляти особистої інформації. Будь-яка розмова і листування залишаться тільки між нами. Ми не маємо корисливої мети, а щиро хочемо допомогти вам упоратись і пережити важку ситуацію",

    "homeHowWeWorkBtn": "Звернутися за підтримкою",

    "homeAboutText1": "Розкажи мені — безкоштовна інтернет-платформа для психологічних консультацій. Ми допомагаємо всім, хто потребує емоційної підтримки, консультації та допомоги у зв’язку зі швидкими змінами, що наразі відбуваються у економіці, соціумі та житті кожної людини.",
    "homeAboutText2": "Команда платформи працює здебільшого за методом когнітивно-поведінкової терапії (КПТ), яка є однією з найпоширеніших форм психотерапевтичної допомоги людям з різними психологічними проблемами.",
    "homeAboutText3": "З нами працюють лише кваліфіковані спеціалісти, з якими ви можете відверто обговорити ситуації, що вас турбують. Усе, що ви розказуєте психологу, залишається тільки між вами.",
    "homeAboutText4": "Платформа розроблена та створена Інститутом глобальної трансформації (Institute for Global Transformation).",

    "forPsychologistsBtn":        "Стати психологом проекту",
    "forPsychologistsMotivation": "Приєднуйтеся, якщо хочете покращувати емоційний стан українців!",

    "invalidEmpty":             "Поле не може бути порожнім",
    "invalidWrongFormatEmail":  "Неправильний формат електронної пошти",
    "invalidEmailAlreadyTaken": "Даний email вже зайнятий",
    "invalidPassFormat":        "Пароль повинен містити тільки цифри, великі та маленькі літери і мінімум 7 символів",
    "invalidPassNoSame":        "Пароль не збігається",
    "invalidPhoneFormat":       "Неправильний формат телефону",
    "invalidPhoneAlreadyTaken": "Даний телефон вже зайнятий",
    "invalidDocFormat":         "Потрібно відправити файл \"doc\" формату",
    "invalidMaxLength":         "Файл не може бути більше 8 МБ",
    "invalidAllowedFormatDoc":  "Дозволені формати файлу - doc, docx, pdf",
    "invalidFileFormat":        "Потрібно відправити хоча б один файл \"png, jpg, pdf\" формату",
    "invalidMax5Files":         "Дозволено не більше 5 файлів",
    "invalidAllowedFormatFile": "Дозволені формати файлу - png, jpg, jpeg або pdf",

    "reviewRequired":     " Це питання обов’язкове",
    "reviewResTitle":     "Дякуємо за відповіді",
    "reviewResDesc":      "Для нас важлива ваша думка. Ця оцінка допоможе нам працювати краще",
    "reviewErr1":         "Ви не можете залишити відгук,",
    "reviewErr2":         "тому що психолога не знайдено",
    "reviewFormTitle":    " Форма зворотного зв’язку платформи психологічної підтримки «Розкажи мені»",
    "reviewFormDescr":    " Нам дуже важливо дізнатися вашу думку про платформу та роботу наших психологів. Ми хочемо надавати якісні послуги та покращувати вашу емоційну стабільність. Тому будемо вдячні, якщо ви дасте відповідь на кілька запитань щодо роботи платформи «Розкажи мені»",
    "reviewReq":          "* Обов'язково",
    "reviewQuestion1":    "Ви задоволені консультацією?",
    "reviewAnswerPhold":  "Моя відповідь",
    "reviewQuestion2":    " Скільки консультацій вам було надано психологом платформи \"Розкажи мені\"",
    "reviewQuestion3":    " Як ви оціните роботу психолога? (за шкалою від 1 до 5, де 1 - не сподобалося, 5 - дуже добре)",
    "reviewQuestion4":    "Коротко опишіть враження від роботи з психологом",
    "reviewQuestion5":    "Коротко опишіть враження від користування платформою і побажання її вдосконалення",
    "reviewName":         "Анонімний користувач",
    "popUpTitlePhone":    "Кількість запитів в цьому місяці вичерпано",
    "popUpMessagePhone":  "Просимо вас звернутись за повторною консультацією через 30 днів, адже кількість запитів цього місяця - вичерпано.",
    "popUpMessagePhone2": "Дякуємо за розуміння!",
    "popUpMessageToBot":  "Поки Ви очікуєте на можливість нового звернення, ви можете поспілкуватися з",
    "overLoadMessage":    "Через велику кількість заявок, які надходять на платформу, час на опрацювання вашого звернення психологом збільшено.",
    "overLoadTitle":      "Платформу перевантажено!",
    "ifNotAdult":         "Якщо Вам ще не виповнилось 18 років, зверніться на Національну дитячу «гарячу лінію»",
    "or":                 "або",
    "forCalling":         "У будні",
    "informPanel":        "Для того щоб обрати декілька варіантів - утримуйте",
    "creator":            "Розроблено:",
    "botHeader1":         "Я – автоматизований співрозмовник, заснований на ",
    "botHeaderGrey":      "когнітивно-поведінковій",
    "botHeader2":         "терапії.",
    "botParagraph":       "Психологічний бот першої допомоги створений для надання рекомендацій щодо того, як боротися з надзвичайно важкими подіями.",
    "botLink":            "ПОЧАТИ СПІЛКУВАННЯ",
    "botMore":            "ДІЗНАТИСЯ БІЛЬШЕ",
    "successToBot":       "Поки ви очікуєте на спілкування із нашим психологом, то можете поспілкуватися з автоматизованим",
    "anchorBot":          " чат-ботом \"ДРУГ\"",
    "btnMore":            "Дивитись більше",
    "btnLess":            "Згорнути",
    "fieldAgeMoreInvalid":"Занадто великий вік",
    "fieldAgeLessInvalid":"Вік не може бути таким малим",
    "fieldAgeLabel":      "Вкажіть ваш вік",
    "fieldAge":           "Скільки вам повних років?",
    "fieldAgeInvalid":    "В полі повинні бути присутні тільки цифри",
    "firstAidHeader":     "Психологічна підтримка",
    "firstAidText":       "Телеграм канал з корисними порадами для збереження та покращення ментального здоров’я",
    "firstAidBtn":        "Приєднатися",
    "fieldNickName":      "Введіть ваш нікнейм, який починається з @",
    "fieldNickNameLabel": "Ваш нікнейм в Telegram",
    "fieldNickNameInvalid": "Поле має починатися з @",
    "fieldNickNameOver": "Забагато символів, можна лише 110",
    "allSpecialists":     "Усі психологи",
    "search":             "Шукати психолога",
    "noResults":          "Нічого не знайдено",
    "feedbackType":       "Введіть номер телефону",
    "infoMessage":        "Наші психологи працюють з різних куточків світу і важливо, щоб телефон, який ви вказали, був в одному з цих месенжерів: Telegram/What’s app/Viber.",
    "beforeSurvey":       "Дякуємо за реєстрацію на сайті. Просимо Вас також пройти опитувальник, це займе до 10 хвилин Вашого часу.",
    "nextStep":           "Наступний крок",
    "getHelpLocationLabel": "Регіон проживання",
    "results-header": "Наші результати"
};

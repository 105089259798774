//Core
import React from 'react';
import { Link } from "react-router-dom";
import { buttonI } from '@/interfaces';
import './style.scss';

//Utils
import { URL_LANG } from '@/utils/constants';

const Btn:React.FC<buttonI> = ({
    url,
    theme,
    title,
    img,
    onPress,
    full,
    nofollow = false,
}) => {

    const renderBtn = () => {
        const _rel = nofollow ? 'nofollow ' : "noreferrer";
        const bot = (theme==='bot' || theme==='botmore' )? true: false;
        const fullBtn = full? 'btn-full' : '';
        const onPressBtn = onPress? onPress: () => { console.log('empty') };

        if (url) {
            if (url.indexOf("http") !== -1) {
                return (
                    <a
                        className = { `${bot?'btn-botbtn':'btn'} btn-${theme} ${fullBtn}` } href = { url } rel = { _rel }  target = '_blank'
                        onClick = { onPressBtn }>
                        {img && <img className = 'btn-img' src = { img } alt = { theme } />}
                        <span className = 'btn_text'>{ title }</span>
                    </a>
                );
            }

            return (
                <Link
                    className = { `btn btn-${theme} ${fullBtn}` } to = { URL_LANG+url } onClick = { onPressBtn }>
                    {img && <img className = 'btn-img' src = { img } alt = { theme } />}
                    <span className = 'btn_text'>{ title }</span>
                </Link>
            );

        }

        return (
            <div
                className = { `btn btn-${theme} ${fullBtn}` } onClick = { onPressBtn }>
                {img && <img className = 'btn-img' src = { img } alt = { theme } />}
                <span className = { `btn_text ${theme}` }>{ title }</span>
            </div>);
    };

    return (<>
        { renderBtn() }
    </>
    );
};

export default Btn;

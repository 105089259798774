export default {
    partnersTitle:        "Наши единомышленники",
    reviewsTitle:         "Отзывы о работе платформы",
    specialistsTitle:     "Ведущие специалисты",
    specialistsDescr:     "Ваша эмоциональная стабильность – наша главная цель. Поэтому в нашей команде работают только сертифицированные специалисты с опытом работы. Мы гарантируем профессионализм психологов строгим отбором.",
    specialistsBtn:       "Все психологи",
    resultTitle:          "Ваша заявка успешно зарегистрирована на сайте \"Расскажи мне\"",
    resultDescript:       "Уже скоро с вами свяжется наш специалист. Ожидайте, и мы поможем вам достичь психологического комфорта.",
    getHelpTitle:         "Обратиться за помощью",
    getHelpLoad:          "Обработка данных",
    getHelpNameLabel:     "Ваше имя",
    getHelpNamePholder:   "Введите имя",
    getHelpSnameLabel:    "Ваша фамилия",
    getHelpSnamePholder:  "Введите фамилию",
    getHelpRadioGender:   "Пол",
    getHelpAdult:         "Подтверждаю, что мне уже есть 18 или больше",
    getHelpTel:           "Номер телефона",
    placeholderPhone:     "Код страны, номер телефона",
    getHelpLabel:         "Что вас беспокоит?",
    areaGetHelpLabel:     "Кратко опишите свой запрос",
    areaGetHelpPholder:   "Начните вводить",
    getHelpFeedBackLabel: "Укажите способ, каким можно свзаться с вами в месенджере",

    getHelpAgreeText:   "Я принимаю Условия конфиденциальности и обработки персональных данных.",
    getHelpAgreeLink:   "Подробнее",
    getHelpTelList:     "У меня нет мессенджеров",
    getHelpMale:        "Мужчина",
    getHelpFemale:      "Женщина",
    contentLoading:     "Загружаемся...",
    modalGetTitle:      "Помогите нам помогать вам!",
    modalBefore:        "Наши специалисты работают как волонтеры-психологи, и мы очень ценим их время. Поэтому призываем вас соблюдать регламент консультации:",
    modalAfter0:        "1). Наши специалисты предоставляют ",
    modalAfter:         " консультацию продолжительностью 30-60 минут (в зависимости от запроса на усмотрение психолога)",
    modalAfterBr:       "Надеемся на ваше понимание и толерантность.",
    modalAfterBr1:      "2). Психолог свяжется с вами с помощью смс, текстового сообщения в мессенджере или звонка для согласования даты и времени консультации. Если вы не отвечаете в течение 2 суток, обращение аннулируется.",
    modalAfterBr2:      "3). Если вы опоздаете на консультацию, ее время сокращается на соответствующее количество минут.",
    modalAfterBr3:      "4). Психолог имеет право прервать консультацию в следующих случаях: если пациент находится под действием наркотических веществ или алкоголя, ведет себя агрессивно и оскорбляет психолога, нарушает порядок во время разговора.",
    modalAfterBr4:      "5). Запросы от несовершеннолетних будут переадресованы на ресурсы партнеров.",
    modalAfterBr5:      "6). Запросы на консультацию принимаются только от первого лица, то есть родственники или знакомые не могут обращаться с просьбой помочь другому человеку. Исключение — несовершеннолетние лица (если обращение от родителей).",
    modalButtonAgree:   "Соглашаюсь",
    modalButtonDecline: "Bернуться",
    resFotmOnErr:       "Произошла техническая ошибка",
    resFotmOnErrDesc:   "Мы уже работаем над ее решением.Попробуйте повторить ваши действия через 15 мин.",
    resFotmOnErrNote:   "Если результат будет таким же, напишите нам на почту",
    giveHelpResTitle:   "Ваша форма успешно заполнена",
    giveHelpResDesc:    "Благодарим вас за то, что присоединились к нашей инициативе. С вами обязательно свяжутся наши специалисты сразу после обработки вашей анкеты",
    giveHelpTitle:      "Присоединиться к инициативе",
    giveHelpLoader:     "Обработка данных...",
    giveHelpEmail:      "Электронная почта",
    giveHelpPassword:   "Повторите пароль",
    giveHelpAgree:      "Подробнее",
    giveHelpAgreeText:  "Я принимаю условия конфиденциальности и обработки персональных данных",
    giveHelpEducation:  "Ваше образование",
    giveHelpEducPehold: "Введите информацию",
    giveHelpSpec:       "Какие ваши сферы специализации",
    giveHelpDownload:   "Выберите файл",
    giveHelpDownEd:     "Добавьте один или несколько документов, подтверждающих квалификацию специалиста",
    giveHelpResume:     "Выберите резюме",
    giveHelpDownResume: "Загрузите Ваше резюме в формате .doc .docx .pdf",
    buttonSubmit:       "Отправить",
    buttonContinue:     "Далее",
    buttonMain:         "На главную",
    fieldSelect:        "Сделайте выбор",
    buttonMore:         "Читать далее",
    buttonLess:         "Свернуть",
    giveHelpSpecError:  "Выберите сферы специализации",
    forbidden:          "Доступ к сервису заблокирован",
    "privacy":        "Конфиденциальность",
    "specialists":    "Специалисты",
    "partners":       "Партнеры",
    "about":          "О нас",
    "howWeWork":      "Как мы работаем",
    "faq":            "FAQ",
    "contacts":       "Контакты",
    "toAdmin":        "Войти",
    "address":        "Адрес",
    "addressLink":    "Институт когнитивного моделирования (ИКМ), ул. Предславинская, 40",
    "social":         "Социальные сети",
    "footerPrivacy1": "Политика конфиденциальности и",
    "footerPrivacy2": "обработка персональных данных",
    "footerRules":    "Правила пользования сайтом",
    "tel":            "Телефон технической поддержки",

    "cookieText":   "Этот сайт использует файлы cookie для повышения удобства работы",
    "cookieLink":   "Подробнее",
    "cookieButton": "Принимаю",

    "homePrivacyTitle": "Только между нами",
    "homePrivacyText":  "Мы понимаем, как трудно иногда бывает открыться постороннему человеку, поэтому все наши пользователи имеют право не сообщать личной информации. Любой разговор и переписка останутся только между нами. Мы не имеем корыстной цели, а искренне хотим помочь вам справиться и пережить трудную ситуацию.",

    "homeHowWeWorkBtn": "Обратиться за поддержкой",

    "homeAboutText1": "Расскажи мне — бесплатная интернет-платформа для психологических консультаций. Мы помогаем всем, кто нуждается в эмоциональной поддержке, консультации и помощи в связи с быстрыми изменениями, которые сейчас происходят в экономике, социуме и жизни каждого человека.",
    "homeAboutText2": "Команда платформы в основном работает по методу когнитивно-поведенческой терапии (КПТ), которая является одной из самых распространенных форм психотерапевтической помощи людям с различными психологическими проблемами.",
    "homeAboutText3": "С нами работают только квалифицированные специалисты, с какими вы можете откровенно обсудить ситуации, которые вас беспокоят. Все, что вы рассказываете психологу, остается только между вами.",
    "homeAboutText4": "Платформа разработана и создана Институтом когнитивного моделирования - научно-исследовательской компанией, предоставляющей полный спектр решений для социальных коммуникаций.",

    "forPsychologistsBtn":        "Стать психологом проекта",
    "forPsychologistsMotivation": "Присоединяйтесь, если хотите улучшать эмоциональное состояние украинцев!",

    "invalidEmpty":             "Поле не может быть пустым",
    "invalidWrongFormatEmail":  "Неправильный формат электронной почты",
    "invalidEmailAlreadyTaken": "Данный email уже занят",
    "invalidPassFormat":        "Пароль должен содержать только цифры, большие и маленькие буквы и минимум 7 символов",
    "invalidPassNoSame":        "Пароль не совпадает",
    "invalidPhoneFormat":       "Неправильный формат телефона",
    "invalidPhoneAlreadyTaken": "Данный телефон уже занят",
    "invalidDocFormat":         "Нужно отправить файл \" doc \" формата",
    "invalidMaxLength":         "Файл не может быть больше 8 МБ",
    "invalidAllowedFormatDoc":  "Разрешены форматы файла - doc, docx, pdf",
    "invalidFileFormat":        "Нужно отправить хотя бы один файл \" png, jpg, pdf \" формата",
    "invalidMax5Files":         "Разрешено не более 5 файлов",
    "invalidAllowedFormatFile": "Разрешены форматы файла - png, jpg, jpeg или pdf",

    "reviewRequired":     " Этот вопрос обязательный",
    "reviewResTitle":     "Благодарим за ответы",
    "reviewResDesc":      "Нам важно ваше мнение. Эта оценка поиможет нам работать лучше",
    "reviewErr1":         "Вы не можете оставить отзыв,",
    "reviewErr2":         "так как психолог не найден",
    "reviewFormTitle":    " Форма обратной связи платформы психологической поддержки «Розкажи мені»",
    "reviewFormDescr":    " Нам очень важно узнать ваше мнение о платформе и роботе наших психологов. Мы хотим предоставлять качественные услуги и улучшать вашу эмоциональную стабильность. Поэтому будем благодарны если вы дадите несколько ответов р работе платформы «Розкажи мені»",
    "reviewReq":          "* Обязательно",
    "reviewQuestion1":    "Вы удовлетворены консультацией?",
    "reviewAnswerPhold":  "Мой ответ",
    "reviewQuestion2":    " Сколько консультаций вам было предоставлено психологом платформы \"Розкажи мені\"",
    "reviewQuestion3":    " Как вы оцените работу психолога? (по шкале от 1 до 5, где 1 - не понравилось, 5 - очень хорошо)",
    "reviewQuestion4":    "Коротко опишите впечатления от работы с психологом",
    "reviewQuestion5":    "Коротко опишите впечатления от пользования платформой и пожелания ее улучшения",
    "reviewName":         "Анонимный пользователь",
    "popUpTitlePhone":    "Количество запросов в этом месяце исчерпано",
    "popUpMessagePhone":  "Просим вас обратиться за повторной консультацией через 30 дней, ведь количество запросов в этом месяце - исчерпано.",
    "popUpMessagePhone2": "Спасибо за понимание!",
    "popUpMessageToBot":  "Пока Вы ожидаете возможности нового обращения, вы можете пообщаться з чат-ботом \"ДРУГ\"",
    "overLoadMessage":    "Из-за большого количества заявок, которые поступают на платформу, время на обработку вашего обращения психологом увеличено.",
    "overLoadTitle":      "Платформа перегружена!",
    "ifNotAdult":         "Если Вам еще нет 18 лет, обратитесь в Национальную детскую «горячую линию»",
    "or":                 "или",
    "forCalling":         "В будни",
    "informPanel":        "Чтобы выбрать несколько вариантов удерживайте",
    "creator":            "Разаработано:",
    "botHeader1":         "Я – автоматический собеседник, основанный на ",
    "botHeaderGrey":      "когнитивно-поведенческой",
    "botHeader2":         "терапии.",
    "botParagraph":       "Психологический бот первой помощи создан для предоставления рекомендаций в борьбе с чрезвычайно тяжелыми событиями.",
    "botLink":            "НАЧАТЬ ОБЩЕНИЕ",
    "botMore":            "УЗНАТЬ БОЛЬШЕ",
    "successToBot":       "Пока Вы ожидаете общения с нашим психологом, вы можете пообщаться с автоматизированным",
    "anchorBot":          "чат-ботом \"ДРУГ\"",
    "btnMore":            "Смотреть больше",
    "btnLess":            "Свернуть",
    "fieldAgeMoreInvalid":"Слиишком большой возраст",
    "fieldAgeLessInvalid":"Возраст не может быть таким маленьким",
    "fieldAgeLabel":      "Укажите ваш возраст",
    "fieldAge":           "Сколько вам полных лет?",
    "fieldAgeInvalid":    "В поле дожны быть только цифры",
    "firstAidHeader":     "Психологическая поддержка",
    "firstAidText":       "Телеграм канал з полезными советами для сохранения и улучшения ментального здоровья",
    "firstAidBtn":        "Присоединиться",
    "fieldNickName":      "Введите ваш никнейм, начинающийся с @",
    "fieldNickNameLabel": "Ваш никнейм в Telegram",
    "fieldNickNameInvalid": "Поле должно начинаться с @",
    "fieldNickNameOver": "Слишком много символов, можно всего 110",
    "allSpecialists":     "Все психологи",
    "search":             "Искать психолога",
    "noResults":          "Ничего не найдено",
    "feedbackType":       "Введите номер телефона",
    "infoMessage":        "Наши психологи работают из разных уголков мира и важно, чтобы телефон, который вы указали, был в одном из этих месенжеров: Telegram/What’s app/Viber.",
    "beforeSurvey":       "Благодарим за регистрацию на сайте. Просим Вас также пройти опросник, это займет до 10 минут Вашего времени.",
    "nextStep":           "Следующий шаг",
    "getHelpLocationLabel": "Регион проживания",
    "results-header": "Наши результаты"
};
